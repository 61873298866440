// Toasts

.toast {
  &.show {
    z-index: 1030;
  }
  .btn-close:focus {
    box-shadow: none;
  }
}

@media (max-width: 420.98px) {
  .toast {
    width: auto;
  }
}

.border-warning-toast-2 {
  border: 2px solid #ff9f43 !important;
}
