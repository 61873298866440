@import '../../bootstrap-extended/include'; // Bootstrap includes
@import '../../components/include'; // Components includes

form {
  span.error {
    width: 100%;
    font-size: 0.857rem;
  }
  .error:not(input) {
    color: $danger;
  }
  input.error,
  input.error:focus {
    border-color: $danger !important;
  }
  textarea.error {
    border-color: $danger !important;
  }
  .select2.error {
    + .select2-container--classic .select2-selection--single,
    + .select2-container--default .select2-selection--single,
    + .select2-container--classic .select2-selection--multiple,
    + .select2-container--default .select2-selection--multiple {
      border-color: $danger !important;
    }
  }
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  background-position: right 1rem center, center right 2.5rem;
}
