/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.stile-btn {
  width: 100%;
  padding: 6px;
  font-size: 12px;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 212px !important;
}

.cell-wrap-text {
  white-space: normal !important;
}

.main-menu.menu-light .navigation .navigation-header span {
  font-weight: 700;
  font-size: 17px;
  color: #e1bf04;
  text-transform: none;
}


ng-select > div > div > div.ng-input:after {
  background-image: none !important;
}

.accordion > .card {
  overflow: visible;
}

.bg-login {
  background-image: url("/assets/images/pages/bg-mizar-login.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (max-height: 625px) {
  .auth-wrapper.auth-v2 .brand-logo {
    position: absolute !important;
  }
}

.main-menu.menu-light .navigation .navigation-header {
  padding: 1.2rem 0 0.8rem 1.2rem;
}

.main-menu.menu-light .navigation .navigation-header span {
  font-size: 15px;
  line-height: 16px;
}

.vertical-overlay-menu .main-menu .navigation > li > a > svg, .vertical-overlay-menu .main-menu .navigation > li > a > i {
  min-width: 20px;
}

.tree-wrapper div.angular-tree-component tree-node > div .toggle-children-wrapper {
  margin-left: 14px !important;
}

.angular-tree-component {
  zoom: 1.2;
}

.toggle-children-wrapper-expanded {
  background-color: #d4e700 !important;
  color: #fff !important;
}

.angular-tree-component {
  cursor: pointer !important;
}

#tree-filter {
  font-size: 16px;
  height: 37px;
}

.tree-wrapper div.angular-tree-component tree-node > div .node-content-wrapper {
  margin: 3px 0px 3px 0px !important;
  padding: 3px 10px;
  width: 100%;
  border-radius: 3px;
}

.tree-wrapper div.angular-tree-component tree-node > div .toggle-children-wrapper {
  margin-top: 9px;
}

.tree-wrapper div.angular-tree-component tree-node > div::before {
  margin-top: 15px;
}

.tree-wrapper div.angular-tree-component tree-node:first-child > div::before {
  margin-top: 19px;
}

.tree-wrapper .node-drop-slot {
  height: 4px;
}


.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.card-body {
  margin-bottom: 20px;
}

.block-ui-wrapper {
  background: rgba(0, 0, 0, 0.30) !important;
}

.select-nb > .ng-select-container {
  border: none;
  background-color: transparent;
  height: 30px !important;
  min-height: 30px !important;
}

.select-nb.ng-select.ng-select-focused .ng-select-container {
  border: none;
  background-color: transparent;
  height: 30px !important;
  min-height: 30px !important;
}

.tree-node-loading {
  display: none;
}

.file-row {
  border-top: dotted 1px #ccc;
  padding-top: 5px;
}

.file-row:hover {
  padding-top: 6px;
  border: none;
}

.mt-big {
  margin-top: $spacer * 6 !important;
}

.main-menu.menu-light .navigation .navigation-header {
  margin: 0;
}

.main-menu.menu-light .navigation > li.active > a {
  box-shadow: none;
}

@media (max-width: 575.98px) {
  html body .app-content {
    padding: 8px calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
}

@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: none !important;
  }
}
/*html .content.app-content {
  padding: calc(0.3rem + 4.4rem + 1.3rem) 2rem 0;
}*/
.main-menu .navbar-header {
  height: 7.2rem;
}
.main-menu.menu-light .navigation > li > ul li:not(.has-sub) {
  margin: 0 28px;
}
.navigation {
  font-size: 1rem;
  font-weight: 500;
}