//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;

$primary: #034787;
$light: #e1e1e1;
$success: #e1bf04;
